import { ApolloClient, HttpLink, ApolloLink, Observable } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { AppConfig } from 'toca-config';
import { Auth } from '@aws-amplify/auth';
import uuid from 'react-uuid';
import { AppUtils } from 'toca-shared-utils';
import { onError } from '@apollo/client/link/error';
import Routes from '../config/Routes';
import { getChannelName } from '../utils/Utils';

const publicOperations = [
  'getBusinessByEmployerRegistrationCode',
  'verifyUser',
  'registerUser',
  'registerBusinessUser',
  'changePassword',
  'forgetPassword',
  'completeUserRegistration',
];

const request = async operation => {
  const {
    targetUserId: userIdFromStore,
    targetUserKey: userKeyFromStore,
    targetBrandId: brandIdFromStore,
  } = AppUtils.getTargetedUserData();

  if (publicOperations.includes(operation.operationName)) {
    return;
  }

  const currentSession = await Auth.currentSession();
  const userIdToken = currentSession.getIdToken().getJwtToken();

  if (
    (await AppUtils.isCurrentUserAdminOrSuperAdmin()) &&
    userKeyFromStore &&
    userIdFromStore &&
    brandIdFromStore
  ) {
    operation.setContext({
      headers: {
        authorization: userIdToken ? `Bearer ${userIdToken}` : '',
        'X-Correlation-Id': uuid(),
        'X-OperationName': operation.operationName,

        'X-TargetBrandId': brandIdFromStore,
        'X-TargetUserKey': userKeyFromStore,
        'X-TargetUserId': userIdFromStore,
        'X-Channel': getChannelName(),
      },
    });
  } else {
    operation.setContext({
      headers: {
        authorization: userIdToken ? `Bearer ${userIdToken}` : '',
        'X-Correlation-Id': uuid(),
        'X-OperationName': operation.operationName,
        'X-Channel': getChannelName(),
      },
    });
  }
};

export const getApolloReqLink = (apolloRequest: any) => {
  return new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle;
        Promise.resolve(operation)
          .then(oper => apolloRequest(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      })
  );
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    const sourceUrl = `${window.location.pathname}`;

    window.location.href = `${AppConfig.userPortalUrl}${Routes.networkErrorPage}?sourceUrl=${sourceUrl}`;
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,

    getApolloReqLink(request),

    new HttpLink({
      uri: AppConfig.bffBaseUrl,
    }),
  ]),
  cache: new InMemoryCache(),
});

export default client;
