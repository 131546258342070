import { useGoogleAnalyticsTracking } from 'hooks/adhoc/useGoogleAnalyticsTracking';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorBoundary, FeatureFlagUser, Loader } from 'toca-shared-ui';
import { AppUtils } from 'toca-shared-utils';
import Routes from '../config/Routes';

import { FeatureFlagList } from 'toca-config';

const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Login = lazy(() =>
  import(/* webpackChunkName: "login" */ './pages/public/Login/LoginScreen')
);

const NewUserRegistration = lazy(() => import('./pages/public/Registration/RegistrationScreen'));
const RegistrationComplete = lazy(() =>
  import('./pages/public/RegistrationComplete/RegistrationCompleteScreen')
);
const ForgotPassword = lazy(() =>
  import('./pages/public/ForgotPassword/ForgotPasswordComponentScreen')
);
const ChangePassword = lazy(() => import('./pages/public/ChangePassword/ChangePasswordScreen'));
const FlxAccounts = lazy(() =>
  import('./pages/secure/Student/FlxAccountsScreen/FlxAccountsScreen')
);
const FlxReferral = lazy(() => import('./pages/public/FlxReferral/FlxReferralScreen'));
const Help = lazy(() => import('./pages/secure/Help/HelpScreen'));

const WalletTopUp = lazy(() => import('./pages/secure/WalletTopUp/WalletTopUpScreen'));
const DefaultPaymentMethod = lazy(() =>
  import('./pages/secure/DefaultPaymentMethod/DefaultPaymentMethodScreen')
);
const RedirectToApp = lazy(() => import('./pages/secure/RedirectToApp/RedirectToApp'));
const AccountHistory = lazy(() => import('./pages/secure/AccountHistory/AccountHistoryScreen'));
const FlxAccountHistory = lazy(() =>
  import('./pages/secure/Student/FlxAccountHistory/FlxAccountHistoryScreen')
);
const AccountClose = lazy(() => import('./pages/secure/AccountClose/AccountCloseScreen'));
const News = lazy(() => import('./pages/secure/News/NewsScreen'));
const OrderPad = lazy(() => import('./pages/secure/Orders/OrderPadScreen'));
const OrderTiles = lazy(() => import('./components/OrderTiles/OrderTilesScreen'));
const OpenOrders = lazy(() => import('./components/OpenOrders/OpenOrders/OpenOrdersScreen'));
const Impersonate = lazy(() => import('./pages/secure/Impersonate/ImpersonateScreen'));
const Logout = lazy(() => import('./pages/secure/Logout/LogoutScreen'));
const FlxSignUp = lazy(() => import('./pages/secure/FLX/FlxSignUpScreen'));
const Student = lazy(() => import('./pages/secure/Student/StudentScreen'));
const AddStudentScreen = lazy(() =>
  import('./pages/secure/Student/AddStudentScreen/AddStudentScreen')
);
const AppAuthenticator = lazy(() => import('./pages/secure/AppAuthenticator/AppAuthenticator'));
const NotFound = lazy(() => import('./components/NotFound/NotFoundScreen'));
const NetworkErrorScreen = lazy(() =>
  import('./pages/public/NetworkErrorScreen/NetworkErrorScreen')
);
const AccountChangePassword = lazy(() =>
  import('./pages/secure/Account/ChangePassword/ChangePasswordScreen')
);
const AccountPreferences = lazy(() =>
  import('./pages/secure/Account/PreferencesScreen/PreferencesScreen')
);
const AccountDetails = lazy(() =>
  import('./pages/secure/Account/AccountDetails/AccountDetailsScreen')
);
const FlxSpendScreen = lazy(() => import('./pages/secure/Student/FlxSpendTile/FlxSpendScreen'));
const FlxSavingsScreen = lazy(() =>
  import('./pages/secure/Student/FlxSavingsTile/FlxSavingsScreen')
);
const FlxEarnScreen = lazy(() => import('./pages/secure/Student/FlxEarnTile/FlxEarnScreen'));
const FlxSignupSelectStudent = lazy(() =>
  import('./pages/secure/FLX/FlxSignupSelectStudentScreen/FlxSignupSelectStudentScreen')
);
const FlxWelcome = lazy(() => import('./pages/secure/FLX/FlxWelcome/FlxWelcomeScreen'));
const FlxAppSignUp = lazy(() => import('./pages/secure/FLX/FlxWelcome/FlxAppSignUpScreen'));

const FlxReplaceCardWithNewDesign = lazy(() =>
  import('./pages/secure/Student/FLXReplaceCardWithNewDesign/FLXReplaceCardWithNewDesign')
);
const SchoolFormsScreen = lazy(() => import('./pages/secure/SchoolForms/SchoolFormsScreen'));
const NewOrder = lazy(() => import('./components/LegacyOrder/LegacyNewOrderScreen'));
const BuzzPost = lazy(() => import('./components/CategorySection/Tile/BuzzPost/BuzzPostScreen'));

const HomeScreen = lazy(() => import('./pages/secure/Home/HomeScreen'));

const ResilientWalletTopUpScreen = lazy(() =>
  import('./pages/secure/WalletTopUp/ResilientWalletTopUpScreen')
);

const AutoTopUpSetupScreen = lazy(() =>
  import('./pages/secure/WalletTopUp/AutoTopUpScreen/AutoTopUpScreen')
);

const PATHS_TO_TRACK_USING_GTM = [Routes.flxSignUp, Routes.flxWelcome];

function UserPortalRoutes<Props>(props: Props) {
  const isAccountOwner = AppUtils.getIsAccountOwner();
  const loadingInWebView = AppUtils.isLoadingInWebView();

  // enable google tracking
  // accepts optional paths to track using gtm
  useGoogleAnalyticsTracking(PATHS_TO_TRACK_USING_GTM);

  return (
    <div>
      <ErrorBoundary>
        <Suspense fallback={<Loader loading></Loader>}>
          <Switch>
            <Route
              exact
              path="/"
              render={param => {
                return <Redirect to={`/${Routes.home}${param.location.search}`} />;
              }}
            />
            <Route exact path={`/${Routes.login}`} component={Login} />

            <Route exact path={`/${Routes.register}`} component={NewUserRegistration} />
            <Route exact path={`/${Routes.registerComplete}`} component={RegistrationComplete} />
            <Route exact path={`/${Routes.forgotPassword}`} component={ForgotPassword} />
            <Route exact path={`/${Routes.changePassword}`} component={ChangePassword} />
            <PrivateRoute path={`/${Routes.home}`} component={HomeScreen} />
            <PrivateRoute
              exact
              path={`/${Routes.accountChangePassword}`}
              component={AccountChangePassword}
            />
            <PrivateRoute
              exact
              path={`/${Routes.userPreferences}`}
              component={AccountPreferences}
            />
            <PrivateRoute exact path={`/${Routes.accountDetails}`} component={AccountDetails} />
            <PrivateRoute path={`/${Routes.help}`} component={Help} />

            <PrivateRoute path={`/${Routes.autoTopUp}`} component={AutoTopUpSetupScreen} />
            <PrivateRoute path={`/${Routes.walletTopUp}`} component={ResilientWalletTopUpScreen} />

            <PrivateRoute
              path={`/${Routes.defaultPaymentMethod}`}
              component={DefaultPaymentMethod}
            />
            <PrivateRoute path={`/${Routes.redirectToApp}`} component={RedirectToApp} />
            <PrivateRoute path={`/${Routes.accountHistory}`} component={AccountHistory} />
            <PrivateRoute path={`/${Routes.accountClose}`} component={AccountClose} />
            <PrivateRoute path={`/${Routes.orders}`} component={OrderTiles} />
            <PrivateRoute path={`/${Routes.schoolForms}`} component={SchoolFormsScreen} />
            <PrivateRoute path={`/${Routes.orderTiles}`} component={OrderTiles} />
            <PrivateRoute path={`/${Routes.newOrder}`} component={NewOrder} />
            <PrivateRoute path={`/${Routes.buzzPost}`} component={BuzzPost} />
            <PrivateRoute path={`/${Routes.openOrders}`} component={OpenOrders} />
            <PrivateRoute path={`/${Routes.news}`} component={News} />
            <PrivateRoute path={`/${Routes.orderPad}`} component={OrderPad} />
            <Route path={`/${Routes.impersonate}`} component={Impersonate} />
            <Route path={`/${Routes.logout}`} component={Logout} />
            <PrivateRoute path={`/${Routes.flxSignUp}`} component={FlxSignUp} />
            <PrivateRoute
              path={`/${Routes.flxSignUpSelectStudent}`}
              component={FlxSignupSelectStudent}
            />
            <PrivateRoute path={`/${Routes.flxWelcome}`} component={FlxWelcome} />
            <PrivateRoute path={`/${Routes.flxAppSignUp}`} component={FlxAppSignUp} />
            <PrivateRoute path={`/${Routes.flxAccountHistory}`} component={FlxAccountHistory} />
            <PrivateRoute
              exact
              path={`/${Routes.flxAccounts}`}
              component={loadingInWebView ? FlxAccounts : isAccountOwner ? FlxAccounts : Student}
            />
            <PrivateRoute exact path={`/${Routes.flxSpendAccount}`} component={FlxSpendScreen} />
            <PrivateRoute
              exact
              path={`/${Routes.flxSavingsAccount}`}
              component={FlxSavingsScreen}
            />
            <PrivateRoute exact path={`/${Routes.flxEarnAccount}`} component={FlxEarnScreen} />
            <Route exact path={`/${Routes.flxReferral}`} component={FlxReferral} />
            <Route
              exact
              path={`/${Routes.flxReplaceCardWithNewDesign}`}
              component={FlxReplaceCardWithNewDesign}
            />
            <PrivateRoute exact path={`/${Routes.addStudent}`} component={AddStudentScreen} />
            <PrivateRoute exact path={`/${Routes.updateStudent}`} component={Student} />
            <Route path={`/${Routes.networkErrorPage}`} component={NetworkErrorScreen} />
            <Route path={`/${Routes.mobileAppAuthenticator}`} component={AppAuthenticator} />
            <PrivateRoute path={`/${Routes.nativeProfile}`} component={Loader} />
            <Route component={NotFound}></Route>
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

export default UserPortalRoutes;
